@tailwind base;
@tailwind components;
@tailwind utilities;

input{
    outline: none;

}


select{
    outline: none;

}

input.input-field:valid + .input-label{
    transform: translateY(-190%);
    font-size: 13px;
    z-index: 999;
    padding-left: 4px;
}


select.input-field:valid + .input-label{
    transform: translateY(-190%);
    font-size: 13px;
    z-index: 999;
    padding-left: 4px;
}


input.input-field:focus  + .input-label{

    transform: translateY(-190%);
    font-size: 13px;
    z-index: 999;
    padding-left: 4px;




}

select.input-field:focus  + .input-label{

    transform: translateY(-190%);
    font-size: 13px;
    z-index: 999;
    padding-left: 4px;




}


.input-label{

}



tr{
    margin-top: 10px;
}